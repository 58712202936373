import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const serverSlice = createApi({
  reducerPath: 'serverSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: ''
  }),
  tagTypes: ['Posts'],
  endpoints: (builder) => ({
    getPaging: builder.query({
      query: ({ url, from, size, sort, filter }) =>
      {
        url += `?from=${from}&size=${size}`;
        if (sort)
        {
          url += `&sort=${sort}`;
        }
        if (filter)
        {
          url += `&filter=${filter}`;
        }

        return url;
      },
      providesTags: (result) =>
      {
        if (result)
        {
          if (result.configurations)
          {
            return [...result.configurations.map(({ id }) => ({ type: 'Posts', id })),
            { type: 'Posts', id: 'LIST' }];
          }
          return [...result.map(({ id }) => ({ type: 'Posts', id })),
          { type: 'Posts', id: 'LIST' }];
        }
        else
        {
          return [];
        }
      }

      // providesTags: (result) =>
      //     result
      //         ? [
      //             ...result.configurations.map(({ id }) => ({ type: 'Posts', id })),
      //             { type: 'Posts', id: 'LIST' },
      //         ]
      //         : [{ type: 'Posts', id: 'LIST' }]
    }),
    create: builder.mutation({
      query: (payload) => ({
        method: 'POST',
        url: payload.url,
        body: payload.body
      }),
      invalidatesTags: [{ type: 'Posts', id: 'LIST' }]
    }),
    update: builder.mutation({
      query: (payload) => ({
        method: 'PUT',
        url: payload.url,
        body: payload.body
      }),
      invalidatesTags: [{ type: 'Posts', id: 'LIST' }],
    }),
    delete: builder.mutation({
      query: (payload) => ({
        method: 'DELETE',
        url: payload.url,
        body: payload.body
      }),
      invalidatesTags: [{ type: 'Posts', id: 'LIST' }],
    }),
  })
});

export const {
  useGetPagingQuery,
  useCreateMutation,
  useUpdateMutation,
  useDeleteMutation
} = serverSlice;
