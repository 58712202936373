import { createSlice } from "@reduxjs/toolkit";

export const datatableSlice = createSlice({
  name: 'datatable',
  initialState: {
    //toast: null,
    data: [{ items: {}, total: 0 }],
    from: 0,
    size: 10,
    sort: [],
    filter: []
  },
  reducers: {
    setData: (state, action) =>
    {
      state.data = action.payload.data;
    },
    setFrom: (state, action) =>
    {
      state.from = action.payload;
    },
    setSize: (state, action) =>
    {
      state.size = action.payload;
    },
    setSort: (state, action) =>
    {
      state.sort = action.payload;
    },
    setFilter: (state, action) =>
    {
      state.filter = action.payload;
    },
    setLoading: (state, action) =>
    {
      state.isLoading = action.payload;
    },
    // addToast: (state, action) =>
    // {
    //     state.toast = action.payload;
    // },
    // removeToast: (state) =>
    // {
    //     state.toast = null;
    // }
  }
});

export const {
  setData,
  setError,
  setFrom,
  setSize,
  setSort,
  setFilter,
  setLoading,
  //addToast,
  removeToast,
} = datatableSlice.actions;

export default datatableSlice.reducer;
