import { createSlice } from "@reduxjs/toolkit";

export const popupSlice = createSlice({
  name: 'popupSlice',
  initialState: {
    configuration: {
      show: false,
      validate: false,
      action: '',
      modalType: ''
    },
    data: {}
  },
  reducers: {
    setProperties: (state, action) =>
    {
      Object.entries(action.payload).forEach(([key, value]) =>
      {
        state.data[key] = value;
      });
    },
    setProperty: (state, action) =>
    {
      return {
        ...state, data: {
          ...state.data, [action.payload.name]: action.payload.value
        }
      };
    },
    clearProperties: (state) =>
    {
      state.data = {};
    },
    open: (state) =>
    {
      return {
        ...state, configuration: {
          ...state.configuration, show: true
        }
      };
    },
    close: (state) =>
    {
      return {
        ...state, configuration: {
          ...state.configuration, show: false
        }
      };
    },
    setValidate: (state, action) =>
    {
      return {
        ...state, configuration: {
          ...state.configuration, validate: action.payload
        }
      };
    },
    //insert, update, delete
    setAction: (state, action) =>
    {
      return {
        ...state, configuration: {
          ...state.configuration, action: action.payload
        }
      };
    },
    setModalType: (state, action) =>
    {
      return {
        ...state, configuration: {
          ...state.configuration, modalType: action.payload
        }
      };
    }
  }
});

export const { setProperties, setProperty, clearProperties, open, close, setAction, setValidate, setModalType } = popupSlice.actions;
export default popupSlice.reducer;
