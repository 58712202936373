import { createSlice } from "@reduxjs/toolkit";

export const pageSlice = createSlice({
  name: 'pageSlice',
  initialState: {
    sidebarShow: true,
    unfoldable: undefined,
    //sidebarUnfoldable: false
  },
  reducers: {
    set: (state, action) =>
    {
      return { ...state, [action.payload.name]: action.payload.value };
    }
  }
});

export const { set } = pageSlice.actions;
export default pageSlice.reducer;
