import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

// const loading = (
//   <div className="pt-3 text-center">
//     <div className="sk-spinner sk-spinner-pulse"></div>
//   </div>
// )

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))


class App extends Component
{
  render()
  {
    return (
      // <HashRouter>
      //   <Suspense >
      //     <Routes>
      //       {/* <Route exact path="/login" name="Login Page" element={<Login />} />
      //       <Route exact path="/register" name="Register Page" element={<Register />} />
      //       <Route exact path="/404" name="Page 404" element={<Page404 />} />
      //       <Route exact path="/500" name="Page 500" element={<Page500 />} /> */}
      //       <Route path="*" name="Home" element={<DefaultLayout />} />
      //     </Routes>
      //   </Suspense>
      // </HashRouter>

      //BrowserRouter: without '?#' on url
      <BrowserRouter >
        <Suspense>
          <Routes>
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App
