import { createSlice } from "@reduxjs/toolkit";

export const paginagionSlice = createSlice({
    name: 'pagination',
    initialState: {
        currentPage: 1,
        dataPerPage: 10
    },
    reducers: {
        setCurrentPage: (state, action) =>
        {
            state.currentPage = action.payload;
        },
        setPageSize: (state, action) =>
        {
            state.dataPerPage = action.payload;
        }
    }
});

export const { setCurrentPage, setPageSize } = paginagionSlice.actions;
export default paginagionSlice.reducer;