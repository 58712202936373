import { configureStore } from "@reduxjs/toolkit";
import paginationReducer from "./reducers/paginationSlice";
import popupReducer from "./reducers/popupSlice";
import datatableReducer from "./reducers/datatableSlice";
import pageReducer from "./reducers/pageSlice";
import { serverSlice } from "./reducers/serverSlice";
// import { serverApi } from "./reducers/serverReducer";
import { setupListeners } from "@reduxjs/toolkit/query";


export const store = configureStore({
  reducer: {
    page: pageReducer,
    pagination: paginationReducer,
    popup: popupReducer,
    datatable: datatableReducer,
    [serverSlice.reducerPath]: serverSlice.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    [serverSlice.middleware]
  )
});

setupListeners(store.dispatch);
